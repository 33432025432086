import React, { useEffect, useState } from "react";
import Spinner from "../UI/Spinner";
import FinancesRow from "./FinancesRow";
import { useDispatch, useSelector } from "react-redux";
import FinanceControllers from "./FinanceControllers";
import ColumnChart from "../UI/charts/ColumnChart";

function FinancesTable({
  userData,
  setSelectedCategory,
  setSelectedDate,
  selectedCategory,
  setOpenModal,
  useCase,
  editModalSetter,
  allPaymentsTrigger,
  selectedDate,
}) {
  const [mode, setMode] = useState("darkmode");
  const [filteredData, setFilteredData] = useState(userData);
  const dispatch = useDispatch();
  const filteredPatients = useSelector(
    (state) => state.patients.filteredPatients
  );

  useEffect(() => {
    if (userData) {
      let sortedData = userData.map((service) => {
        if (service.payments.length > 1) {
          let sortedService = service.payments.sort((a, b) => {
            let checkedB =
              typeof b.date !== "string" ? b.date.toDate() : b.date;

            let checkedA =
              typeof a.date !== "string" ? a.date.toDate() : a.date;

            return checkedB - checkedA;
          });
          service.payments = sortedService;
        }
        return service;
      });

      let sortByService = sortedData.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      setFilteredData(sortByService);
    }
  }, [userData]);

  return (
    <>
      <div
        className={`w-full ${
          useCase !== "payments" && "h-[91vh]"
        } rounded-lg shadow ${
          mode !== "darkmode" ? "bg-white" : "bg-slate-600"
        } ${useCase === "payments" ? "bg-white" : "bg-slate-600"} `}
      >
        <div
          className={`flex ${
            useCase === "payments" ? "justify-left" : "justify-center"
          } mx-2 md:m-5 sm:flex-col-reverse md:flex-row flex-col-reverse`}
        >
          <div
            className={`flex w-full ${
              useCase === "payments" ? "items-center" : "items-baseline"
            }`}
          >
            <div
              className={`flex justify-start ${
                useCase === "payments" ? "m-2" : "m-1"
              }`}
            >
              {useCase === "payments" && (
                <div
                  className="m-1 p-1 flex text-white rounded-md"
                  style={{ backgroundColor: "#253141" }}
                  onClick={() => setOpenModal(true)}
                  title="Agregar nuevo Pago o Servicio"
                >
                  <div className="px-1 font-bold h-[4.5vh] w-[10vw] md:w-[5vw] lg:w-[3vw] flex justify-center items-center">
                    +
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col md:justify-between w-full">
              <FinanceControllers
                setSelectedCategory={setSelectedCategory}
                currentCategory={selectedCategory}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                filteredData={filteredData}
              />
              {useCase !== "payments" && <ColumnChart data={userData} />}
            </div>

            {/* <div className="flex items-center justify-center min-h-screen bg-gray-100"> */}
            {/* <ColumnChart data={data} /> */}
            {/* </div> */}
          </div>
          {/* <div className="flex items-center">
            {useCase !== "payments" && (
              <div
                className={`m-1 p-1 flex ${
                  mode === "darkmode" ? "bg-yellow-400" : "bg-cyan-950"
                } text-white rounded-md`}
              >
                {mode === "darkmode" ? (
                  <div className="flex" onClick={() => setMode("lightmode")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="flex" onClick={() => setMode("darkmode")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}
          </div> */}
        </div>

        {/* <div
          className={`overflow-x-auto no-scrollbar shadow-md sm:rounded-lg m-2 ${
            useCase === "payments" ? "h-[57vh]" : "h-[60vh]"
          }`}
        >
          <div className="">
            <div className="min-w-full relative w-full text-sm text-left rtl:text-right text-gray-500 text-gray-400">
              <div className="bg-gray-200 text-gray-700 text-xs text-gray-700 sticky top-0 uppercase bg-gray-50 bg-gray-700 text-gray-400">
                <div className="grid grid-cols-12 gap-0 flex">
                  <div className="p-4 font-bold text-white col-span-2 flex justify-left border-b border-gray-300">
                    Servicio
                  </div>
                  <div
                    className={`py-4 font-bold  text-white ${
                      useCase === "payments" ? "col-span-5" : "col-span-4"
                    } flex justify-center border-b border-gray-300`}
                  >
                    Descripción
                  </div>
                  <div className="py-4 font-bold text-white col-span-1 flex justify-center border-b border-gray-300">
                    Fecha
                  </div>
                  <div className="py-4 font-bold text-white col-span-1 flex justify-center border-b border-gray-300">
                    Precio
                  </div>
                  <div className="py-4 font-bold text-white col-span-1.5 flex justify-center border-b border-gray-300">
                    Estado
                  </div>
                  <div className="py-4 font-bold text-white col-span-1 flex justify-center border-b border-gray-300">
                    Pendiente
                  </div>
                  {useCase !== "payments" && (
                    <div className="py-4 font-bold text-white col-span-1 flex justify-center border-b border-gray-300">
                      Paciente
                    </div>
                  )}
                  <div className="py-4 text-white col-auto flex justify-left border-b border-gray-300"></div>
                </div>
              </div>

              {filteredData ? (
                <tbody className="flex flex-col">
                  {filteredData.map((m, i) => (
                    <FinancesRow
                      reason={m.reason}
                      description={m.description}
                      hasBeenPaid={m.hasBeenPaid}
                      date={m.date}
                      balance={m.balance}
                      price={m.price}
                      id={m.id}
                      key={i}
                      patient={m.patientName}
                      patientId={m.patientId}
                      mode={mode}
                      payments={m.payments}
                      useCase={useCase}
                      editModalSetter={editModalSetter}
                      rowData={m}
                      allPaymentsTrigger={allPaymentsTrigger}
                    />
                  ))}
                </tbody>
              ) : (
                <div
                  className="flex items-center justify-center"
                  style={{ height: "70vh", width: "100vw" }}
                >
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div> */}

        <div
          className={`shadow-md sm:rounded-lg m-2 ${
            useCase === "payments" ? "h-[57vh]" : "h-[60vh]"
          }`}
        >
          {/* <div className="">
            <div className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <div className="w-full bg-gray-200 text-gray-700 text-xs uppercase sticky top-0 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 overflow-x-auto">
                <div className="flex md:grid md:grid-cols-12">
                  <div className="p-4 font-bold text-white col-span-1 md:col-span-2 flex justify-left border-b border-gray-300">
                    Servicio
                  </div>
                  <div
                    className={`py-4 font-bold text-white ${
                      useCase === "payments" ? "md:col-span-5" : "md:col-span-4"
                    } flex justify-center border-b border-gray-300`}
                  >
                    Descripción
                  </div>
                  <div className="py-4 font-bold text-white col-span-1 flex justify-center border-b border-gray-300">
                    Fecha
                  </div>
                  <div className="py-4 font-bold text-white col-span-1 flex justify-center border-b border-gray-300">
                    Precio
                  </div>
                  <div className="py-4 font-bold text-white col-span-1 md:col-span-1.5 flex justify-center border-b border-gray-300">
                    Estado
                  </div>
                  <div className="py-4 font-bold text-white col-span-1 flex justify-center border-b border-gray-300">
                    Pendiente
                  </div>
                  {useCase !== "payments" && (
                    <div className="py-4 font-bold text-white col-span-1 flex justify-center border-b border-gray-300">
                      Paciente
                    </div>
                  )}
                  <div className="py-4 text-white col-auto flex justify-left border-b border-gray-300"></div>
                </div>
              </div>

              {filteredData ? (
                <>
                  {filteredData.map((m, i) => (
                    <FinancesRow
                      reason={m.reason}
                      description={m.description}
                      hasBeenPaid={m.hasBeenPaid}
                      date={m.date}
                      balance={m.balance}
                      price={m.price}
                      id={m.id}
                      key={i}
                      patient={m.patientName}
                      patientId={m.patientId}
                      mode={mode}
                      payments={m.payments}
                      useCase={useCase}
                      editModalSetter={editModalSetter}
                      rowData={m}
                      allPaymentsTrigger={allPaymentsTrigger}
                    />
                  ))}
                </>
              ) : (
                <div
                  className="flex items-center justify-center"
                  style={{ height: "70vh", width: "100vw" }}
                >
                  <Spinner />
                </div>
              )}
            </div>
          </div> */}

          <div className="overflow-x-auto no-scrollbar shadow-md sm:rounded-lg ml-2 h-[80vh] rounded-lg">
            <table className="relative w-full text-sm text-left rtl:text-right text-gray-500 text-gray-400">
              <thead className="text-xs text-gray-700 sticky top-0 uppercase bg-gray-50 bg-gray-700 text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 text-white">
                    Servicio
                  </th>
                  <th scope="col" className="px-6 py-3 text-white w-3/4">
                    Descripción
                  </th>
                  <th scope="col" className="px-6 py-3 text-white">
                    Fecha
                  </th>
                  <th scope="col" className="px-6 py-3 text-white">
                    Precio
                  </th>
                  <th scope="col" className="px-6 py-3 text-white">
                    Estado
                  </th>
                  <th scope="col" className="px-6 py-3 text-white">
                    Pendiente
                  </th>
                  {useCase !== "payments" && (
                    <th scope="col" className="px-6 py-3 text-white">
                      Paciente
                    </th>
                  )}
                  <th scope="col" className="px-6 py-3 text-white"></th>
                </tr>
              </thead>

              {filteredData && filteredData?.length > 0 ? (
                filteredData.map((m, i) => {
                  return (
                    <tbody className="">
                      <FinancesRow
                        reason={m.reason}
                        description={m.description}
                        hasBeenPaid={m.hasBeenPaid}
                        date={m.date}
                        balance={m.balance}
                        price={m.price}
                        id={m.id}
                        key={i}
                        patient={m.patientName}
                        patientId={m.patientId}
                        mode={mode}
                        payments={m.payments}
                        useCase={useCase}
                        editModalSetter={editModalSetter}
                        rowData={m}
                        allPaymentsTrigger={allPaymentsTrigger}
                      />
                    </tbody>
                  );
                })
              ) : (
                <tbody>
                  <tr>
                    <td
                      className="flex items-center justify-center"
                      style={{ height: "70vh", width: "100vw" }}
                    >
                      {filteredData?.length <= 0 ? (
                        <div
                          className={
                            useCase === "payments"
                              ? "text-slate-800"
                              : `text-white`
                          }
                        >
                          Agregá un pago para visualizarlo aquí
                        </div>
                      ) : (
                        <Spinner />
                      )}
                    </td>
                  </tr>
                </tbody>
              )}

              {/* {filteredData && filteredData?.length > 0 ? (
                <tbody className="">
                  {filteredData.map((m, i) => (
                    <PatientRow
                      name={m.personalDetails.name}
                      tel={m.personalDetails.tel}
                      birthdate={m.personalDetails.birthdate}
                      gender={m.personalDetails.gender}
                      visit={m.odontologicPast.lastVisit}
                      id={m.id}
                      mode={mode}
                      url={m.id}
                      payments={m.payments}
                      key={i}
                    />
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      className="flex items-center justify-center"
                      style={{ height: "70vh", width: "100vw" }}
                    >
                      {filteredData?.length <= 0 ? (
                        <div className="text-white">
                          Agregá un paciente para visualizarlo aquí
                        </div>
                      ) : (
                        <Spinner />
                      )}
                    </td>
                  </tr>
                </tbody>
              )} */}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinancesTable;
