import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { currentRawDate } from "./firebase.helpers";
import { generateUuid } from "./firebase.helpers";
import { toast } from "react-hot-toast";
import { db } from "./firebase";
import { logError } from "./firebase.errortracker";

const updateOrAddPayment = async (
  userId,
  serviceId,
  data,
  modalSetter,
  allPaymentsSetter,
  setServiceIsRunning
) => {
  const paymentDocRef = doc(db, "payments", userId);
  try {
    const docSnap = await getDoc(paymentDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
    } else {
      debugger;
      data.id = generateUuid();
      debugger;
      await setDoc(doc(db, "payments", data.id), data);
      // allPaymentsSetter(data.services);
      toast.success("Pago agregado exitosamente");
      modalSetter(false);

      // // Add payment
      // paymentAmount.id = newId;
      // paymentAmount.date = paymentAmount.date;
      // if (data.services !== undefined && data.services.length > 0) {
      //   data.services = [...data.services, paymentAmount];
      // } else {
      //   data.services = [paymentAmount];
    }
    // }
    // await updateDoc(doc(db, "patients", userId), data);
    // allPaymentsSetter(data.services);
    // toast.success("Pago agregado exitosamente");
    // modalSetter(false);
    // setServiceIsRunning(false);
    // } else {
    //   toast.error("Usuario no encontrado");
    // }
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const deletePaymentItem = async (
  userId,
  serviceId,
  // modalSetter,
  allPaymentsSetter
  // setServiceIsRunning
) => {
  const userDocRef = doc(db, "patients", userId);

  try {
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.services) {
        // Find the index of the service to delete
        let isServiceIndex = data.services.findIndex(
          (service) => service.id === serviceId
        );

        let isPaymentIndex;
        let serviceIndex;
        if (isServiceIndex === -1) {
          data.services.map((e, i) => {
            let itemIndex = e.payments.findIndex(
              (service) => service.id === serviceId
            );
            if (itemIndex !== -1) {
              isPaymentIndex = itemIndex;
              serviceIndex = i;
            }
            // if (isPaymentIndex !== -1) {
            //   // serviceIndex = isPresent;
            // }
          });
        }

        if (isServiceIndex !== -1 || isPaymentIndex !== -1) {
          if (isServiceIndex !== -1) {
            // Delete the service
            data.services.splice(isServiceIndex, 1);
          }
          if (isPaymentIndex !== -1 && serviceIndex !== undefined) {
            data.services[serviceIndex].balance =
              data.services[serviceIndex].balance +
              data.services[serviceIndex].payments[isPaymentIndex].amount;

            if (data.services[serviceIndex].balance !== 0) {
              data.services[serviceIndex].hasBeenPaid = false;
            } else {
              data.services[serviceIndex].hasBeenPaid = true;
            }
            // Delete the service
            data.services[serviceIndex].payments.splice(isPaymentIndex, 1);
          }
          // Update the document
          await updateDoc(doc(db, "patients", userId), data);

          allPaymentsSetter(data.services);
          toast.success("Servicio eliminado exitosamente");
          // modalSetter(false);
          // setServiceIsRunning(false);
        } else {
          toast.error("Servicio no encontrado");
        }
      } else {
        toast.error("No hay servicios para eliminar");
      }
    } else {
      toast.error("Usuario no encontrado");
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al eliminar el servicio. Por favor inténtelo nuevamente."
    );
    console.error(err);
  }
};

const getPaymentsByUserId = async (userId) => {
  const userDocRef = doc(db, "patients", userId);
  try {
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.services;
    } else {
      toast.error("Usuario no encontrado");
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

// const getActivePaymentsByUserId = async (userId) => {
//   const userDocRef = doc(db, "patients", userId);
//   try {
//     const docSnap = await getDoc(userDocRef);
//     if (docSnap.exists()) {
//       const data = docSnap.data();
//       let activeData = data?.services?.filter((e) => !e.hasBeenPaid);
//       return activeData;
//     } else {
//       toast.error("Usuario no encontrado");
//     }
//   } catch (err) {
//     toast.error(
//       "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
//     );
//     console.log(err);
//   }
// };

const getActivePaymentsByUserId = async (patientId) => {
  /** Fetching remote data from Firestore */
  // // debugger;
  try {
    console.log(patientId);
    const calendarCollectionRef = query(
      collection(db, "payments"),
      where("patientId", "==", patientId),
      where("hasBeenPaid", "==", false)
    );
    const querySnapshot = await getDocs(calendarCollectionRef);

    if (!querySnapshot.empty) {
      // Process the data
      const events = querySnapshot.docs.map((doc) => doc.data());
      return events;
    } else {
      console.log("No documents found in the 'payments' collection.");
      // Handle this case as needed
    }
  } catch (error) {
    console.error("Error fetching remote events: ", error);
    throw error;
  }
};

const getAllPaymentsByPraxisId = async (praxisId) => {
  try {
    const data = [];
    const q = query(
      collection(db, "payments"),
      where("praxisId", "==", praxisId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const allPaymentsByUIdAndPId = async (praxisId, patientId) => {
  try {
    const data = [];
    const q = query(
      collection(db, "payments"),
      where("praxisId", "==", praxisId),
      where("patientId", "==", patientId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const addPayment = async (
  serviceId,
  service,
  newPayment,
  modalSetter,
  allPaymentsSetter
) => {
  const paymentDocRef = doc(db, "payments", serviceId);
  try {
    const paymentDocSnapshot = await getDoc(paymentDocRef);

    if (paymentDocSnapshot.exists()) {
      // const paymentData = paymentDocSnapshot.data();
      debugger;
      service.payments.push(newPayment);
      service.balance = service.balance - newPayment.amount;
      if (service.balance === 0) {
        service.hasBeenPaid = true;
      }
      debugger;
      console.log(service);
      // await setDoc(doc(db, "payments", serviceId), service);
      await updateDoc(paymentDocRef, service);
      // allPaymentsSetter(data.services);
      toast.success("Pago agregado exitosamente");
      modalSetter(false);
      allPaymentsSetter(service);
      return service;
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const updatePayment = async (
  serviceId,
  type,
  paymentId,
  updatedPayment,
  //
  service,
  newPayment,
  modalSetter,
  allPaymentsSetter
) => {
  const paymentDocRef = doc(db, "payments", serviceId);
  try {
    const paymentDocSnapshot = await getDoc(paymentDocRef);

    if (paymentDocSnapshot.exists()) {
      debugger;
      if (type === "service") {
        service.payments.push(newPayment);
        service.balance = service.balance - newPayment.amount;
        if (service.balance === 0) {
          service.hasBeenPaid = true;
        }
        debugger;
        console.log(service);
        await updateDoc(paymentDocRef, service);
        toast.success("Pago agregado exitosamente");
      } else if (type === "payment") {
        const paymentData = paymentDocSnapshot.data();
        debugger;
        const selectedPayment = paymentData?.payments.find(
          (e) => e.id == paymentId
        );
        debugger;
        selectedPayment = updatedPayment;
        // await updateDoc(paymentDocRef, service);
      }
      // allPaymentsSetter(data.services);
      // toast.success("Pago agregado exitosamente");
      modalSetter(false);
      allPaymentsSetter(service);
      return service;
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al agregar el pago. Por favor inténtelo nuevamente."
    );
    console.log(err);
  }
};

const updatePaymentItem = async (service) => {
  debugger;
  var serviceDocRef;
  if (service.parentId) {
    serviceDocRef = doc(db, "payments", service.parentId);
  } else {
    serviceDocRef = doc(db, "payments", service.id);
  }
  try {
    const docSnap = await getDoc(serviceDocRef);
    var docToUpdate = service?.parentId;
    if (docSnap.exists()) {
      debugger;
      if (service.parentId) {
        let allPayments = docSnap.data();
        delete service.parentId;
        var newBalance = 0;
        let updatedService = allPayments?.payments?.map((e) => {
          if (e.id == service.id) {
            e = service;
            newBalance = newBalance + e.amount;
            return e;
          } else {
            newBalance = newBalance + e.amount;
            return e;
          }
        });
        allPayments.balance = allPayments.price - newBalance;
        allPayments.payments = updatedService;
        if (newBalance === allPayments.price) {
          allPayments.hasBeenPaid = true;
        } else {
          allPayments.hasBeenPaid = false;
        }

        await updateDoc(doc(db, "payments", docToUpdate), allPayments);
        toast.success("Pago actualizado exitosamente");
      } else {
        var newBalance = 0;
        service?.payments?.map((e) => {
          newBalance = newBalance + e.amount;
        });
        service.balance = service.price - newBalance;
        if (service.price - newBalance === 0) {
          service.hasBeenPaid = true;
        } else {
          service.hasBeenPaid = false;
        }
        await updateDoc(doc(db, "payments", service.id), service);
        toast.success("Servicio actualizado exitosamente");
      }
    } else {
      console.log("Usuario no encontrado");
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al actualizar el servicio/pago. Porfavor intentelo nuevamente."
    );
    logError(
      `Issues while trying to update service for patientid: ${docToUpdate}. Track: ${err}`
    );
    console.log(err);
  }
};

const deleteService = async (paymentId) => {
  debugger;
  try {
    const documentRef = doc(db, "payments", paymentId);
    await deleteDoc(documentRef);
    toast.success(`Pago con ID ${paymentId} eliminado exitosamente`);
  } catch (error) {
    toast.error(`Error al eliminar el pago`);
  }
};

const deletePayment = async (payment, serviceId) => {
  debugger;
  try {
    const documentRef = doc(db, "payments", serviceId);
    const docSnap = await getDoc(documentRef);
    if (docSnap.exists()) {
      let allPayments = docSnap.data();
      let remainingPayments = [];
      let currentBalance = 0;
      allPayments.payments.map((e) => {
        if (e.id !== payment.id) {
          remainingPayments.push(e);
          currentBalance = currentBalance + e.amount;
        }
      });

      allPayments.payments = remainingPayments;
      allPayments.balance = allPayments.price - currentBalance;
      if (allPayments.balance === 0) {
        allPayments.hasBeenPaid = true;
      } else {
        allPayments.hasBeenPaid = false;
      }

      // await deleteDoc(documentRef);
      await updateDoc(doc(db, "payments", serviceId), allPayments);
      toast.success(`Pago con ID ${payment.id} eliminado exitosamente`);
    }
  } catch (error) {
    toast.error(`Error al eliminar el pago`);
  }
};

export {
  updateOrAddPayment,
  deletePaymentItem,
  getPaymentsByUserId,
  getActivePaymentsByUserId,
  getAllPaymentsByPraxisId,
  allPaymentsByUIdAndPId,
  addPayment,
  updatePayment,
  updatePaymentItem,
  deletePayment,
  deleteService,
};
