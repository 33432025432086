import React from "react";

const Pill = ({ color, text, type, price, balance }) => {
  return (
    <>
      {color == "default" && (
        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-blue-400">
          {text}
        </span>
      )}
      {color == "dark" && (
        <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-gray-500">
          {text}
        </span>
      )}
      {color == "red" && (
        <span
          style={{
            color: "#F5222D",
          }}
          className="bg-red-100 text-xs font-medium me-2 px-1 py-0.5 rounded border border-red-300"
        >
          {text}
        </span>
      )}
      {color == "green" && (
        <span
          style={{
            color: "#5FBD00",
            borderColor: "#5FBD00",
            backgroundColor: "#5FBD0033",
          }}
          className="bg-green-100 text-xs font-medium me-2 px-1 py-0.5 rounded border"
        >
          {text}
        </span>
      )}
      {color == "yellow" && (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-yellow-300">
          {text}
        </span>
      )}
      {color == "indigo" && (
        <span className="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-indigo-400">
          {text}
        </span>
      )}
      {color == "purple" && (
        <span className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-purple-400">
          {text}
        </span>
      )}
      {color == "pink" && (
        <span className="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-pink-400">
          {text}
        </span>
      )}
      {type == "progress" && (
        <div className="pt-0.5 flex flex-col items-center w-full">
          <div className="flex">
            <div>
              {`${
                balance == price
                  ? "0%"
                  : `${(100 - (balance / price) * 100).toFixed()}%`
              }`}
            </div>
          </div>

          <div className="w-full bg-gray-200 items-center rounded-full bg-gray-700 min-w-[4vw]">
            <div
              className="bg-blue-600 h-1 rounded-full"
              style={{
                width: `${
                  balance == price ? "0%" : `${100 - (balance / price) * 100}%`
                }`,
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Pill;
