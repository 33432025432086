import React, { useState, useEffect } from "react";
import NationalityDropdown from "../../UI/NationalityDropdown";
import { checkIfExists } from "../../../firebase/firebase.patient";
import { addExistingPatient } from "../../../firebase/firebase.praxis";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Dropdown from "../../UI/Dropdown";
import Spinner from "../../UI/Spinner";

const PatientBasicDetails = ({
  patientDetails,
  detailsSetter,
  setCanContinue,
}) => {
  const [name, setName] = useState(null);
  const [tutorName, setTutorName] = useState(null);
  const [tel, setTel] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [birthdate, setBirthdate] = useState(null);
  const [age, setAge] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [gender, setGender] = useState(null);
  const [profession, setProfession] = useState(null);
  const [personalAddress, setPersonalAddress] = useState(null);
  const [email, setEmail] = useState(null);
  const [ci, setCI] = useState(null);
  const [invalidEmail, setInvalidEmail] = useState(null);
  const [patientExists, setPatientExists] = useState(false);
  const [odontoId, setodontoId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  const navigate = useNavigate();

  const newPatientDetails = {
    name: name,
    tutorName: tutorName,
    tel: tel,
    birthdate: birthdate,
    maritalStatus: maritalStatus,
    gender: gender,
    profession: profession,
    personalAddress: personalAddress,
    email: email,
    identityNumber: ci,
    nationality: nationality?.label?.split(" ")[1],
    odontoId: `${ci}-${nationality?.value}`,
    identityNumber: ci,
  };

  useEffect(() => {
    detailsSetter(newPatientDetails);
    const storedDetails = localStorage.getItem("patientDetails");
    // debugger;
    if (JSON.stringify(newPatientDetails) !== storedDetails) {
      if (storedDetails === "null") {
        localStorage.setItem(
          "patientDetails",
          JSON.stringify(newPatientDetails)
        );
      } else {
        if (typeof storedDetails !== "string") {
          debugger;
          localStorage.setItem("patientDetails", JSON.stringify(storedDetails));
        } else {
          debugger;
          localStorage.setItem("patientDetails", storedDetails);
        }
      }
    }
  }, [
    name,
    tutorName,
    tel,
    nationality,
    birthdate,
    ci,
    gender,
    profession,
    personalAddress,
    email,
  ]);

  useEffect(() => {
    // Retrieve data from localStorage
    let storedDetails = localStorage.getItem("patientDetails");
    if (typeof storedDetails === "string") {
      storedDetails = JSON.parse(storedDetails);
    }
    debugger;
    if (storedDetails?.email !== undefined) {
      // Parse the data and populate the states
      // const patientDetails = JSON.parse(storedDetails);

      // Update your state variables with the data
      setName(storedDetails?.name || "");
      setCI(storedDetails?.identityNumber || "");
      setTel(storedDetails?.tel || "");
      setNationality(storedDetails?.nationality || "");
      setBirthdate(storedDetails?.birthdate || "");
      setEmail(storedDetails?.email || "");
    }
  }, []); // No dependencies since you only want this

  const handleValidation = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email?.length > 0) {
      if (emailRegex.test(email)) {
        setInvalidEmail(false);
        setEmail(email);
      } else {
        setInvalidEmail(true);
        setEmail(email);
      }
    } else {
      setInvalidEmail(true);
    }
  };

  useEffect(() => {
    if (name && tel && nationality && email && !invalidEmail && ci) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [name, tel, nationality, email, ci, invalidEmail]);

  useEffect(() => {
    if (ci && nationality) {
      let odontoIdstr = `${ci}-${nationality?.value}`;
      setodontoId(odontoIdstr);
      if (ci.length > 6) {
        let checkUsrs = async () => {
          let exists = await checkIfExists(odontoIdstr);
          if (exists?.length > 0) {
            setPatientExists(true);
          } else {
            setPatientExists(false);
          }
          return exists;
        };
        checkUsrs();
      } else {
        setPatientExists(false);
      }
    }
  }, [ci, nationality]);

  const addPatientToPraxis = async () => {
    // TODO: Check if patient exists and add to new praxis
    setIsLoading(true);
    let newPatient = await addExistingPatient(odontoId, localPraxis.praxisId);
    if (newPatient?.id) {
      setTimeout(() => {
        setIsLoading(false);
        navigate(`/${newPatient.id}`);
      }, 3000);
    }
  };

  let formStyle =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";

  return (
    <>
      {/* First row */}
      <div className="">
        <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
          <div className="m-2 sm:w-4/12 md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12">
            <label className="">
              Nombre
              <span className="text-red-500">*</span>
            </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="m-2 sm:w-4/12 md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12">
            <label>
              C.I. <span className="text-red-500">*</span>
            </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required={true}
              onChange={(e) => setCI(e.target.value)}
              value={ci}
            />
            {patientExists && (
              <div className="">
                <p className="text-sm/[1px]">
                  El paciente ya tiene una cuenta.
                </p>
              </div>
            )}
            {patientExists && (
              <button
                className="flex items-center text-xs text-green-800"
                onClick={addPatientToPraxis}
                disabled={isLoading}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m-6 3.75 3 3m0 0 3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75"
                  />
                </svg>

                <label>
                  <div className="flex flex-col">
                    {patientExists && "Importar paciente"}
                    {isLoading && <Spinner size={"xs"} />}
                  </div>
                </label>
              </button>
            )}
          </div>

          <div className="m-2 sm:w-4/12 md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12">
            <label>
              Nacionalidad <span className="text-red-500">*</span>
            </label>
            <NationalityDropdown
              currentValueSetter={(e) => setNationality(JSON.parse(e))}
              className={formStyle}
            />
          </div>
        </div>
      </div>

      {/* Second row */}
      <div className="">
        <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
          <div className="m-2 sm:w-4/12 md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12">
            <label className="">
              Tel Particular <span className="text-red-500">*</span>
            </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              type="number"
              onChange={(e) => setTel(e.target.value)}
              value={tel}
            />
          </div>
          <div className="m-2 sm:w-4/12 md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12">
            <label>
              Email <span className="text-red-500">*</span>
            </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              type="text"
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              title="Invalid email address"
              onChange={(e) => handleValidation(e.target.value)}
              value={email}
            />
            {invalidEmail && (
              <label className="text-xs text-red-500">
                El email es invalido
              </label>
            )}
          </div>
          <div className="m-2 sm:w-3/12 md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12">
            <label className="">Nacimiento</label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              onChange={(e) => setBirthdate(e.target.value)}
              type="date"
              value={birthdate}
            />
          </div>
        </div>
      </div>

      {/* Fourth row */}
      {ci && name && tel && nationality && email ? null : (
        <div className="ml-3 text-xs text-red-500">
          Favor agregar los campos obligatorios para pasar al siguiente paso
        </div>
      )}
    </>
  );
};

export default PatientBasicDetails;
