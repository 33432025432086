import { useState } from "react";

const useNotifications = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const sendNotification = async (message) => {
    setLoading(true);
    setError(null);
    debugger;
    try {
      const response = await fetch(
        "http://127.0.0.1:5001/odontostarkdev/us-central1/sendNotification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token:
              "BH0wfgMHz-OU78flglOAxnriFM2_RO5PaO5iijId-3fbj-liDDsRlbZJdkk88j2h9NhTTbbmWMDhVXfWOpnmX1A",
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, response, sendNotification };
};

export default useNotifications;
