import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerOwner } from "../../firebase/firebase.user";
import { auth } from "../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  setUserCredetials,
  setUserDetails,
} from "../../store/user/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import { useParams } from "react-router-dom";
import usePumble from "../../hooks/pumble/usePumble";
import logo from "../../resources/logo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [praxisTel, setPraxisTel] = useState("");
  const [praxisName, setPraxisName] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [referal, setReferal] = useState(null);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const userCredentials = useSelector((state) => state.user?.userCredentials);
  const userDetails = useSelector((state) => state.user?.userDetails);
  const dispatch = useDispatch();
  const params = useParams();
  const { loading, error, response, sendRegister } = usePumble();

  useEffect(() => {
    // setIsLoading(true);
    if (params?.praxisReferalId !== undefined) {
      debugger;
      setReferal(params?.praxisReferalId);
      // setClientId(params.id);
      // if (allClients) {
      //   let selectedUser = allClients.find((e) => e.id == params.id);
      //   setClientData(selectedUser);
      //   setIsLoading(false);
      // }

      // if (!allClients && !clientDetails) {
      //   let getUser = async (params) => {
      //     let selectedUser = await getClientById(params);
      //     setClientData(selectedUser);
      //     dispatch(setClientById(selectedUser));
      //     setIsLoading(false);
      //   };
      //   getUser(params.id);
      // }
    }
  }, []);

  const praxisOwner = {
    user: {
      // id: authUser.user.uid, // Use the Firebase Authentication user ID as the document ID
      name: name,
      lastname: lastName,
      phone: null,
      praxisPhone: praxisTel,
      email: email,
      praxis: null,
      address: null,
      role: "owner",
      praxisId: null,
    },
    praxis: {
      praxisName: praxisName,
      address: null,
      praxisPhone: praxisTel,
      createdAt: new Date().toISOString(),
      currentLicenseType: "premium",
      payments: [
        {
          paid: false,
          membershipStart: new Date(),
          amount: 0,
          paymentDate: null,
          licenseType: "free",
          invoice: null,
          additionalInfo: "Self Registered",
        },
      ],
      WAMessage: {
        message:
          "Hola%20{nombre},%20estas%20hablando%20con%20el%20{consultorio}.%20Te%20esperamos%20a%20las%20{comienza}%20en%20{dirección}",
      },
      praxisEmail: "",
    },
  };

  useEffect(() => {
    if (user && userDetails) {
      navigate("/dashboard");
    }
  }, [user, userDetails]);

  useEffect(() => {
    if (user && userCredentials === null) {
      let deserializedUser = JSON.parse(JSON.stringify(user));
      dispatch(setUserCredetials(deserializedUser));
    }
  }, [user]);

  const registerUser = async () => {
    setIsLoading(true);
    debugger;
    const registerUser = await registerOwner(praxisOwner, referal);
    if (registerUser) {
      dispatch(setUserDetails(registerUser));
      setIsLoading(false);
      sendRegister(`Nuevo usuario Creado: ${registerUser?.email}`);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex min-h-[100vh] items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 mt-14">
        <div className="flex flex-col">
          <img src={logo} />
        </div>
        <div className="mt-4">
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="name" className="sr-only">
                Nombre
              </label>
              <input
                id="name"
                name="name"
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                className="relative block w-full appearance-none m-2 rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Nombre"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="sr-only">
                Apellido
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                required
                onChange={(e) => setLastName(e.target.value)}
                className="relative block w-full appearance-none m-2 rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Apellido"
              />
            </div>
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                className="relative block w-full appearance-none m-2 rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Nombre del consultorio/Profesional
              </label>
              <input
                id="praxisName"
                name="Nombre del consultorio"
                type="text"
                required
                className="relative block w-full appearance-none m-2 rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Nombre del Consultorio/Profesional"
                onChange={(e) => setPraxisName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Telefono del Consultorio/Profesional
              </label>
              <input
                id="praxisTel"
                name="Telefono del consultorio"
                type="tel"
                required
                className="relative block w-full appearance-none m-2 rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Telefono del Consultorio/Profesional"
                onChange={(e) => setPraxisTel(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-10">
            <button
              onClick={registerUser}
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-[#98bbdf] hover:bg-[#b7cde8] py-2 px-4 text-sm font-medium text-white hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Registrarse
              {isLoading && <Spinner size="small" />}
            </button>
          </div>
          <div className="flex flex-col items-center justify-center mt-3">
            <div className="text-sm">
              <Link
                to="/login"
                className="font-medium text-[#98bbdf] hover:text-[#b7cde8]"
              >
                Iniciar Sesion
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
