import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  logInWithEmailAndPassword,
  sendPasswordReset,
} from "../../firebase/firebase.user";
import { auth } from "../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import logo from "../../resources/logo.png";
import {
  setUserCredetials,
  setUserDetails,
} from "../../store/user/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import usePumble from "../../hooks/pumble/usePumble";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const userCredentials = useSelector((state) => state.user?.userCredentials);
  const userDetails = useSelector((state) => state.user?.userDetails);
  const { loading, error, response, sendLogin } = usePumble();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && userDetails) {
      debugger;
      if (userDetails.role === "owner" || userDetails.role === "user") {
        navigate("/dashboard");
      } else if (userDetails.role === "patient") {
        navigate(`/self/${userDetails.patientRecordId}`);
      } else if (userDetails.role === "admin") {
        navigate(`/admin`);
      }
    }
  }, [user, userDetails]);

  useEffect(() => {
    if (user && userCredentials === null) {
      let deserializedUser = JSON.parse(JSON.stringify(user));
      dispatch(setUserCredetials(deserializedUser));
    }
  }, [user]);

  const logIn = async () => {
    setIsLoading(true);
    const loginUserData = await logInWithEmailAndPassword(email, password);
    if (loginUserData) {
      dispatch(setUserDetails(loginUserData));
      setIsLoading(false);
      sendLogin(`Inicio de Sesion de ${loginUserData?.email}`);
    }
    setIsLoading(false);
  };

  const passwordReset = async () => {
    await sendPasswordReset(email);
  };

  return (
    <div className="flex min-h-[100vh] items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 mt-14">
        <div className="flex flex-col">
          <img src={logo} />
        </div>
        <div className="mt-4">
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-10">
            <button
              onClick={logIn}
              className={`group relative flex w-full bg-[#98bbdf] hover:bg-[#b7cde8] justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none`}
            >
              Iniciar Sesion
              {isLoading && <Spinner size="small" />}
            </button>
          </div>

          <div className="flex flex-col items-center justify-center mt-3">
            <div className="text-sm">
              <a
                onClick={passwordReset}
                className="font-medium text-[#98bbdf] hover:text-[#b7cde8]"
              >
                Reestablecer contraseña
              </a>
            </div>
            <div className="text-sm">
              <Link
                to="/registrarse"
                className="font-medium text-[#98bbdf] hover:text-[#b7cde8]"
              >
                Crear cuenta
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
