import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import History from "../patientViews/allViews/history/History";
import Odontogram from "../patientOverview/patientInformation/odontogram/Odontogram 2";
import ImgDashboard from "../patientViews/allViews/imgDashboard/ImgDashboard";
import Legals from "../patientViews/allViews/legal/Legals";
import AddPatient from "../addPatient/AddPatient";
import HeaderActions from "../../UI/HeaderActions";
import Navbar from "../../navbar/Navbar";
import Spinner from "../../UI/Spinner";
import { getDataByUserId } from "../../../firebase/firebase.patient";
import Payments from "../patientViews/allViews/payments/Payments";
import FeatureFlag from "../../featureFlag/FeatureFlag";
import { ClientViewContext } from "../../../context/clientView/clientView.context";

const PatientSelfView = () => {
  const [data, setData] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [showMore, setShowMore] = useState(true);
  const { state, dispatch } = useContext(ClientViewContext);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  let params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id !== clientId) {
      setClientId(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    let getNewData = async () => {
      let data = await getDataByUserId(params.id);
      if (data !== undefined) {
        // data.praxisName = localPraxis.praxis;
        // data.appointmentStart = "12/12/2024";
        setData(data);
      } else {
        navigate("/dashboard");
      }
    };
    if (clientId && localPraxis) {
      getNewData();
    }
  }, [clientId, localPraxis]);

  return (
    <>
      <Navbar>
        {data ? (
          <>
            <main
              className="flex flex-wrap bg-slate-800 h-full"
              style={{ justifyContent: "center" }}
            >
              <div className="mx-auto w-full">
                <div className="w-full bg-white rounded-t-lg h-full">
                  <div className="flex"></div>
                  <div className="flex">
                    <div className="bg-white rounded-t-lg w-full max-h-[91vh]">
                      {data && (
                        <HeaderActions
                          title={"Agregar Usuario"}
                          userId={data.id}
                          view="patientView"
                        />
                      )}
                      {state && data && state.clientView === "Ficha" && (
                        <div
                        // className={`${!data && "m-5"}`}
                        >
                          <AddPatient
                            headerTitle={`${data.personalDetails.name}`}
                            data={data}
                            setData={setData}
                            editMode={false}
                            view="patientView"
                          />{" "}
                        </div>
                      )}
                      {state && data && state.clientView === "Img" && (
                        <div className="">
                          <FeatureFlag feature={"premium"}>
                            <ImgDashboard
                              data={data}
                              userId={data.id}
                              praxisId={data.praxisId}
                              user={localPraxis}
                              view="patientView"
                            />{" "}
                          </FeatureFlag>
                        </div>
                      )}
                      {state && data && state.clientView === "Odontograma" && (
                        <div className="mt-10">
                          <Odontogram data={data} />
                        </div>
                      )}
                      {state && data && state.clientView === "Historial" && (
                        <div className="m-5">
                          <History
                            rawHistory={data.history}
                            data={data}
                            patientId={clientId}
                            praxisName={localPraxis.praxis}
                            showMoreStatus={showMore}
                            view="patientView"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        ) : (
          <div
            className="flex items-center justify-center bg-white rounded-t-lg"
            style={{ height: "90.7vh" }}
          >
            <Spinner />
          </div>
        )}
      </Navbar>
    </>
  );
};

export default PatientSelfView;
