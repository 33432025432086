import React, { useState, useEffect, useRef } from "react";
import { patientVerification } from "../../firebase/firebase.patient";
import useEmail from "../../hooks/email/useSendEmail";

const ConfirmationPopOver = ({ popoverState, stats }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const popoverRef = useRef(null);
  const { sendVerifyAccount } = useEmail();

  useEffect(() => {
    setIsPopoverVisible(popoverState);
  }, [popoverState]);

  // Handle clicks outside the popover to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsPopoverVisible(false);
      }
    };

    // Add the event listener for clicks outside the popover
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts or updates
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleYesClick = () => {
    // Handle the Yes action (e.g., send verification)
    console.log("Verification sent!");
    setIsPopoverVisible(false);
    debugger;
    const handleSendEmail = async () => {
      const userId = "user123"; // Replace with your user ID logic
      const email = "maxrowk@gmail.com";
      debugger;
      try {
        debugger;
        sendVerifyAccount(stats?.personalDetails?.email, stats.id);
        // await patientVerification("patients", stats.id, stats);
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }

        alert("Email sent successfully!");
      } catch (error) {
        console.error("Error sending email:", error);
        alert("Error sending email");
      }
    };
    handleSendEmail();
  };

  const handleNoClick = () => {
    // Handle the No action (close the popover)
    console.log("No verification");
    setIsPopoverVisible(false);
  };

  if (!isPopoverVisible) {
    return null;
  }

  return (
    <div className="relative">
      <div
        ref={popoverRef}
        className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-white border border-gray-300 shadow-lg p-4 rounded-md mt-2 z-50 w-52"
      >
        <p className="text-sm font-medium text-gray-700 mb-4">
          Enviar Email de validación
        </p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={handleNoClick}
            className="px-4 py-2 text-sm bg-gray-200 hover:bg-gray-300 rounded-md"
          >
            No
          </button>
          <button
            onClick={handleYesClick}
            className="px-4 py-2 text-sm bg-blue-600 hover:bg-blue-700 text-white rounded-md"
          >
            Si
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopOver;
