import React, { useEffect, useState } from "react";
import { monthFormatter } from "../../utils/dateFormatter";
import AI from "../AI/AI";

const FinanceControllers = ({
  setSelectedCategory,
  currentCategory,
  setSelectedDate,
  selectedDate,
  filteredData,
}) => {
  const [date, setDate] = useState(undefined);
  const [newCurrentCategory, setNewCurrentCategory] = useState(currentCategory);

  useEffect(() => {
    if (selectedDate === undefined) {
      setDate("");
    } else {
      setDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (currentCategory) {
      setNewCurrentCategory(currentCategory);
    }
  }, [currentCategory]);

  const onChangeDate = (e) => {
    if (e.target.value === "") {
      setSelectedDate(e.target.value);
      setSelectedCategory("all");
    } else {
      setSelectedDate(monthFormatter(new Date(e.target.value)));
      setSelectedCategory("month");
    }
  };

  return (
    <>
      <div
        className="flex items-center lg:w-[40%] md:w-[50%] w-[95%]"
        style={{ justifyContent: "flex-start" }}
      >
        <button
          value="all"
          className={`px-4 py-2 text-sm font-medium text-gray-900 ${
            newCurrentCategory == "all" || newCurrentCategory == "month"
              ? "bg-gray-100"
              : "bg-white"
          } border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 focus:z-10 rounded-sm w-1/4`}
          onClick={(e) => setSelectedCategory(e.target.value)}
        >
          Todos
        </button>
        <button
          value="pending"
          onClick={(e) => setSelectedCategory(e.target.value)}
          className={`px-4 py-2 text-sm font-medium text-gray-900 ${
            newCurrentCategory == "pending" ? "bg-gray-100" : "bg-white"
          } border-t border-b border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 rounded-sm`}
        >
          Pendientes
        </button>
        <button
          value="paid"
          onClick={(e) => setSelectedCategory(e.target.value)}
          className={`px-4 py-2 text-sm font-medium text-gray-900 ${
            newCurrentCategory == "paid" ? "bg-gray-100" : "bg-white"
          } border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 focus:z-10 rounded-sm w-1/4`}
        >
          Pagados
        </button>
        <input
          type="month"
          lang="es"
          value={date}
          onChange={(e) => onChangeDate(e)}
          className={`px-2 py-[0.4rem] w-1/4 m-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-md hover:bg-gray-100 hover:text-gray-700 focus:z-10`}
        />
        {/* <AI type="finance" financeContent={JSON.stringify(filteredData)} /> */}
      </div>
    </>
  );
};

export default FinanceControllers;
