import React, { useState } from "react";
import Dropdown from "../../UI/Dropdown";
import {
  clientPoster,
  clientUpdater,
} from "../../../firebase/firebase.patient";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { calculateAge } from "../../../utils/calculateAge";
import odontogramModel from "../../patient/addPatient//odontogramModel";
import { DatePickerIcon } from "../../UI/DatePickerIcon";
import NationalityDropdown from "../../UI/NationalityDropdown";
import PatientStats from "../../patient/patientOverview/patientStats/PatientStats";
import userThree from "../../../resources/1043.jpg";
import PraxisStats from "./PraxisStatts";
import PraxisForm from "./PraxisForm";
import SaveButton from "../../UI/SaveButton";
import PraxisUsers from "./praxisUsers/PraxisUsers";
import { praxisUpdater } from "../../../firebase/firebase.praxis";
import Referrals from "./referals/Referrals";
import { setPraxisDetails } from "../../../store/praxis/praxis.reducer";

const PraxisSettings = ({ editMode, headerTitle, data, setData }) => {
  const navigate = useNavigate();
  const [editModeState, setEditModeState] = useState(true);
  const [newdata, setNewData] = useState(null);
  const praxis = useSelector((state) => state.praxis?.praxisDetails);

  const [praxtisId, setPraxisId] = useState(null);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  //Has Submited
  const [hasSubmited, setHasSubmited] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (localPraxis && localPraxis.praxisId) {
      setPraxisId(localPraxis?.praxisId);
    }
  }, [localPraxis]);

  let formStyle = `text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg ${
    editModeState ? "bg-gray-200" : ""
  } ring-offset-current ring-offset-2 ring-gray-400`;

  // let client = {
  //   id: id,
  //   praxisId: praxtisId,
  //   odontoId: nationality?.value ? `${ci}-${nationality?.value}` : odontoId,
  //   personalDetails: {
  //     name: name,
  //     tutorName: tutorName,
  //     tel: tel,
  //     nationality: nationality?.label
  //       ? nationality?.label?.split(" ")[1]
  //       : nationality,
  //     birthdate: birthdate,
  //     maritalStatus: maritalStatus,
  //     gender: gender,
  //     profession: profession,
  //     personalAddress: personalAddress,
  //     email: email,
  //     identityNumber: ci,
  //     taxName: taxName,
  //     ruc: ruc,
  //   },
  //   medicHistory: {
  //     isCurrentlyUnderMedicalTreatment: isCurrentlyUnderMedicalTreatment,
  //     isUsingMedicine: isUsingMedicine,
  //     needsBloodTransf: needsBloodTransf,
  //     hadSurgery: hadSurgery,
  //     alergyToMedicine: alergyToMedicine,
  //     smokes: smokes,
  //     drinks: drinks,
  //     pregnant: pregnant,
  //     canUseAnestesia: canUseAnestesia,
  //   },
  //   odontograma: odontogramModel,
  // };

  // const saveClientHandler = async () => {
  //   if (praxtisId == null) {
  //     toast.error(`Porfavor vuelva a iniciar sesion`, "Sign Out Error");
  //     return;
  //   }
  //   if (editMode === undefined && id === null) {
  //     let newId = Math.random().toString(16).slice(2);
  //     setId(newId);
  //     // client.id = newId;
  //     clientPoster("patients", newId, client, setHasSubmited);
  //   } else {
  //     await clientUpdater("patients", id, client, setHasSubmited);
  //     setEditModeState(false);
  //     setData(client);
  //   }
  // };

  // useEffect(() => {
  //   if (hasSubmited) {
  //     navigate(`/${id}`);
  //   }
  // }, [hasSubmited]);

  // useEffect(() => {
  //   if (editMode !== undefined) {
  //     setEditModeState(editMode);
  //   }
  // }, [editMode]);

  const newPraxis = {
    ...praxis,
    address: newdata?.address,
    praxisEmail: newdata?.email,
    praxisName: newdata?.name,
    praxisPhone: newdata?.tel,
    taxName: newdata?.taxName,
    ruc: newdata?.ruc,
  };

  const saveClientHandler = async () => {
    try {
      setHasSubmited(true);
      // clientPoster("patients", newId, client, setHasSubmited);
      await praxisUpdater(newPraxis);
      toast.success("Cambios guardados exitosamente");
      dispatch(setPraxisDetails(newPraxis));
      setHasSubmited(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <main
        className="flex bg-slate-600 h-full"
        style={{ justifyContent: "center" }}
      >
        <div className="mx-auto w-full">
          <div className="w-full  rounded-t-lg h-full">
            <div className="flex"></div>
            <div className="flex">
              <div className="flex justify-between flex-col md:flex-row lg:flex-row w-full">
                <dic className="lg:w-2/4 px-3 md:p-5">
                  <PraxisStats praxis={praxis} localPraxis={localPraxis} />
                  <PraxisUsers praxis={praxis} />
                  <Referrals praxis={praxis} />
                  <div className="text-xs text-white">
                    v.{process.env.REACT_APP_VERSION}
                  </div>
                </dic>

                <div className="lg:w-2/4 px-1 md:p-5">
                  <div className="flex flex-col m-2">
                    <div className="flex">
                      <div className="personalDetails w-full rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <PraxisForm
                          formStyle={formStyle}
                          praxis={praxis}
                          newDataSetter={setNewData}
                        />
                      </div>
                    </div>
                    <SaveButton
                      saveClientHandler={saveClientHandler}
                      disabled={hasSubmited}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PraxisSettings;
