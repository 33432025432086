import {
  auth,
  db,
  getToken,
  messaging,
  onMessage,
} from "../../firebase/firebase";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserCredetials,
  setUserDetails,
} from "../../store/user/user.reducer";
import { getUserData } from "../../firebase/firebase.user";
import Spinner from "../UI/Spinner";
import toast from "react-hot-toast";
import { setPraxisDetails } from "../../store/praxis/praxis.reducer";
import { getPraxisData } from "../../firebase/firebase.praxis";
import { dateFormatter } from "../../utils/dateFormatter";
import useFCMToken from "../../hooks/FCM/useFCMToken";
import firebase from "../../firebase/firebase";
import { doc, setDoc } from "firebase/firestore";

export const PrivateRoute = ({ element, fallbackPath = "/login" }) => {
  const [userToken, setUserToken] = useState(null);
  const [user, loading] = useAuthState(auth);
  const dispatch = useDispatch();
  const localPraxis = useSelector((state) => state.user?.userDetails);
  const praxis = useSelector((state) => state.praxis?.praxisDetails);
  // const token = useFCMToken();

  useEffect(() => {
    if (user && userToken == null) {
      let userCredentials = JSON.parse(JSON.stringify(user));
      dispatch(setUserCredetials(userCredentials));

      const getUser = async () => {
        toast.loading("Autenticando");
        let userData = await getUserData(userCredentials.uid);
        let praxisData = await getPraxisData(userData.praxisId);
        if (praxisData?.payment?.membershipStart) {
          praxisData.payment.membershipStart.nanoseconds !== undefined
            ? new Date(praxisData.payment.membershipStart)
            : (praxisData.payment.membershipStart = dateFormatter(
                praxisData.payment.membershipStart.toDate()
              ));
        }
        if (praxisData?.WAMessage?.date) {
          praxisData.WAMessage.date.nanoseconds !== undefined
            ? new Date(praxisData.WAMessage.date)
            : (praxisData.WAMessage.date = dateFormatter(
                praxisData.WAMessage.date.toDate()
              ));
        }
        if (userData) {
          dispatch(setUserDetails(userData));
          dispatch(setPraxisDetails(praxisData));
          toast.dismiss();
        }
      };

      if (localPraxis === null || praxis === null) {
        getUser();
      }
      setUserToken(user);
    }
  }, [user]);

  // useEffect(() => {
  //   if (user && userToken == null) {
  //     const messaging = messaging();
  //     debugger;
  //     messaging.onMessage((payload) => {
  //       console.log("Message received in the app:", payload);
  //       // Customize how you handle notifications in-app
  //       new Notification(payload.notification.title, {
  //         body: payload.notification.body,
  //         icon: payload.notification.icon,
  //       });
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   const requestNotificationPermission = async () => {
  //     try {
  //       const currentToken = await getToken(messaging, {
  //         vapidKey: "YOUR_VAPID_KEY",
  //       });
  //       if (currentToken) {
  //         console.log("Token received:", currentToken);
  //         // Send this token to your backend to store it against the user
  //       } else {
  //         console.log(
  //           "No registration token available. Request permission to generate one."
  //         );
  //       }
  //     } catch (error) {
  //       console.error("An error occurred while retrieving token. ", error);
  //     }
  //   };

  //   requestNotificationPermission();

  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     // Show notification
  //     new Notification(payload.notification.title, {
  //       body: payload.notification.body,
  //       icon: payload.notification.icon,
  //     });
  //   });
  // }, []);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((err) => {
        console.error("Service Worker registration failed:", err);
      });
  }

  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        debugger;
        console.log(process.env.REACT_APP_VAPID_KEY);
        const currentToken = await getToken(messaging, {
          vapidKey: `${process.env.REACT_APP_VAPID_KEY}`,
        });
        if (currentToken) {
          debugger;
          console.log("Token received:", currentToken);
          debugger;
          const userId = user?.uid; // Replace with actual user ID
          debugger;
          // Store the token in Firestore under the user's document
          await setDoc(
            doc(db, "users", userId),
            { fcmToken: currentToken },
            { merge: true }
          );
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      } catch (error) {
        console.error("An error occurred while retrieving token. ", error);
      }
    };

    if (user?.uid) requestNotificationPermission();
  }, [user?.uid]);

  // return <div>Your App</div>;
  // };

  if (loading) {
    return (
      <>
        <div className="bg-gray-800">
          <div className="mx-auto max-w-7xl px-6 sm:px-8 md:px-8 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center justify-center sm:items-stretch sm:justify-start sm:w-1/3">
                <div className="flex flex-shrink-0 items-center">
                  <h6 className="mb-0 font-bold text-white capitalize text-xl ml-3">
                    OdontoStark
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex items-center justify-center"
          style={{ height: "80vh" }}
        >
          <Spinner />
        </div>
      </>
    );
  }

  if (user) {
    return element;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};

export default PrivateRoute;
