import { useState } from "react";
import { useSelector } from "react-redux";

const useWhatsApp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const praxis = useSelector((state) => state.praxis?.praxisDetails);

  const sendWhatsAppMessage = async (message) => {
    setLoading(true);
    setError(null);
    debugger;
    let { patientName, praxisName, start, patientTel } = message;
    // to use function send in body the following properties:
    // {{1}} - Patient's name
    // {{2}} - Clinic name
    // {{3}} - Appointment date
    // {{5}} - Location
    // {{6}} - Contact number
    try {
      console.log(praxis);
      debugger;
      const response = await fetch(
        "http://127.0.0.1:5001/odontostarkdev/us-central1/sendReminderBot",
        // "https://us-central1-odontostark-66ab6.cloudfunctions.net/sendBotMessage",
        // "http://127.0.0.1:4000/functions/sendBotMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            to: patientTel,
            message: message,
            patientName: patientName,
            praxisName: praxisName,
            start: start,
            address: praxis?.address,
            praxisPhone: praxis?.praxisPhone,
          }),
        }
      );
      debugger;
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      debugger;
      const data = await response.json();
      setResponse(data);
    } catch (error) {
      debugger;
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  //   const authenticateAndCreateNewUser = async (
  //     patientEmail,
  //     patientId,
  //     praxisId
  //   ) => {
  //     setLoading(true);
  //     setError(null);
  //     try {
  //       debugger;
  //       const response = await fetch(
  //         "https://us-central1-odontostark-66ab6.cloudfunctions.net/sendCreateAccountEmail",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             email: patientEmail,
  //             userId: patientId,
  //             praxisId: praxisId,
  //           }),
  //         }
  //       );
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }

  //       const data = await response.json();
  //       setResponse(data);
  //     } catch (error) {
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  return {
    loading,
    error,
    response,
    sendWhatsAppMessage,
  };
};

export default useWhatsApp;
