import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import { generateUuid } from "./firebase.helpers";
import toast from "react-hot-toast";

const logError = async (errorDetails, user) => {
  debugger;
  try {
    // Get the current user from Firebase authentication
    let currentUrl = window.location.href;
    debugger;
    // Add the error details and user UID to the Firestore collection
    let newId = generateUuid();
    let data = {
      timestamp: new Date(),
      errorDetails: errorDetails,
      isActive: true,
      id: newId,
      currentURL: currentUrl,
      contacted: false,
      confirmed: false,
      user: user,
    };

    await setDoc(doc(db, "errorLogs", newId), data);
    debugger;
    console.log("Error logged successfully.");
  } catch (error) {
    console.error("Error logging to Firestore:", error);
  }
};

const toggleActiveness = async (errorLog) => {
  debugger;
  try {
    await updateDoc(doc(db, "errorLogs", errorLog?.id), errorLog);
    toast.success("errorLog State successfully updated");
  } catch {
    toast.error("There was an issue updating the error.");
  }
};

const deleteErrorLog = async (errorId) => {
  debugger;
  try {
    const documentRef = doc(db, "errorLogs", errorId);
    await deleteDoc(documentRef);
    toast.success(
      `Document with ID ${errorId} deleted successfully from collection errorLogs`
    );
  } catch (error) {
    toast.error(`Error deleting document: ${error}`);
  }
};

export { logError, toggleActiveness, deleteErrorLog };
