import React, { useState, useEffect } from "react";
import { getData } from "../../firebase/firebase.patient";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import Navbar from "../navbar/Navbar";
import SelfCalendar from "./SelfCalendar";

const SelfCalendarHome = () => {
  const localPraxis = useSelector((state) => state.user?.userDetails);
  return (
    <>
      <Navbar>
        <SelfCalendar userData={localPraxis} />
      </Navbar>
    </>
  );
};

export default SelfCalendarHome;
