import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Spinner from "../Spinner";

const ColumnChart = ({ data }) => {
  const [chartsData, setChartsData] = useState();

  function groupIncomeByMonth(payments) {
    const monthNames = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    const monthMap = {
      Ene: "01",
      Feb: "02",
      Mar: "03",
      Abr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Ago: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dic: "12",
    };

    // Helper function to format date as "Month Year"
    const formatDateKey = (date) => {
      const month = date.getMonth();
      const year = date.getFullYear();
      return `${monthNames[month]} ${year}`;
    };

    // Helper function to parse payment date and return a Date object
    const parsePaymentDate = (payment) => {
      return typeof payment.date === "string"
        ? new Date(payment.date)
        : new Date(payment.date.seconds * 1000);
    };

    // Accumulate income by month and year
    const incomeByMonth = payments.reduce((acc, payment) => {
      if (payment.payments && payment.payments.length > 0) {
        payment.payments.forEach((p) => {
          const date = parsePaymentDate(p);
          const key = formatDateKey(date);

          acc[key] = (acc[key] || 0) + p.amount;
        });
      }
      return acc;
    }, {});

    // Convert to array and sort by date
    return Object.entries(incomeByMonth)
      .map(([key, value]) => ({ year: key, Ingresos: value }))
      .sort((a, b) => {
        const [monthA, yearA] = a.year.split(" ");
        const [monthB, yearB] = b.year.split(" ");
        const dateA = new Date(`${yearA}-${monthMap[monthA]}-01`);
        const dateB = new Date(`${yearB}-${monthMap[monthB]}-01`);
        return dateA - dateB;
      });
  }

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    console.log(data);
    if (data) {
      const groupedPayments = groupIncomeByMonth(data);
      console.log(groupedPayments);
      setChartsData(groupedPayments);
    }
  }, [data]);

  return (
    <div className="h-[20vh] w-[98%] bg-slate-400 shadow-lg rounded-lg flex items-center justify-center">
      {chartsData ? (
        <ResponsiveContainer width="95%" height="90%">
          <BarChart
            data={chartsData}
            margin={{ top: 0, right: 0, left: 35, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
            <XAxis
              dataKey="year"
              tick={{ fill: "#FFFFFF", fontWeight: "500" }}
              axisLine={{ stroke: "#D1D5DB" }}
              tickLine={{ stroke: "#D1D5DB" }}
            />
            <YAxis
              tickFormatter={formatNumber}
              tick={{ fill: "#FFFFFF", fontWeight: "500" }}
              axisLine={{ stroke: "#D1D5DB" }}
              tickLine={{ stroke: "#D1D5DB" }}
            />
            <Tooltip
              formatter={(value) => formatNumber(value)}
              contentStyle={{
                backgroundColor: "#1F2937",
                borderColor: "#374151",
              }}
              itemStyle={{ color: "#FFFFFF" }}
              labelStyle={{ color: "#FFFFFF" }}
            />
            <Bar
              dataKey="Ingresos"
              fill="#4F46E5"
              barSize={30}
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div
          className="flex items-center justify-center"
          style={{ height: "100%", width: "100%" }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default ColumnChart;
