import React, { useEffect, useState } from "react";
import { calculateAge } from "../../../../utils/calculateAge";
import NameAvatar from "../../../UI/NameAvatar";
import PatientValidation from "./PatientValidation";

const PatientStats = ({ stats, showmoreSetter }) => {
  const [showMore, setShowMore] = useState(true);
  const [age, setAge] = useState(null);

  useEffect(() => {
    if (stats?.personalDetails?.birthdate) {
      const [day, month, year] = stats?.personalDetails?.birthdate.split("-");
      var convertedBd = new Date(`${year}-${month}-${day}`);
      let currentAge = calculateAge(convertedBd);
      setAge(currentAge?.years);
    }
  }, [stats?.personalDetails?.birthdate]);

  return (
    <div className="mx-auto w-full">
      <div
        className={`${
          showMore ? "p-5" : "px-5 py-4 -mb-5 md:-mb-0 lg:-mb-0"
        } flex flex-col md:flex-row items-center justify-between`}
      >
        <div className="flex items-center w-full md:w-auto mb-4 md:mb-0">
          <div className="flex items-center space-x-4">
            <div
              className="w-14 h-14 bg-gray-600 rounded-full flex items-center justify-center relative overflow-hidden"
              style={{ backgroundColor: "rgb(37, 49, 65)", opacity: 0.7 }}
            >
              <NameAvatar name={stats?.personalDetails?.name} />
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <span className="text-lg font-semibold mr-1">
                  {stats?.personalDetails?.name}
                </span>
                <PatientValidation
                  hasValidated={stats?.verified === undefined}
                  stats={stats}
                />
              </div>
              {stats?.personalDetails?.birthdate && (
                <div className="flex items-center text-sm text-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75-1.5.75a3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0L3 16.5m15-3.379a48.474 48.474 0 0 0-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 0 1 6 13.12M12.265 3.11a.375.375 0 1 1-.53 0L12 2.845l.265.265Zm-3 0a.375.375 0 1 1-.53 0L9 2.845l.265.265Zm6 0a.375.375 0 1 1-.53 0L15 2.845l.265.265Z"
                    />
                  </svg>
                  {age}
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => {
              setShowMore(!showMore);
              showmoreSetter(!showMore);
            }}
            className="md:hidden lg:hidden"
          >
            {!showMore ? (
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-3 h-3 mb-5 ml-1"
              >
                <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
              </svg>
            ) : (
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-3 h-3 mb-5 ml-1"
              >
                <path d="M3 19h18a1.002 1.002 0 00.823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 003 19z" />
              </svg>
            )}
          </div>
        </div>
        {showMore && (
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 w-full md:w-auto">
            <div className="flex flex-col space-y-2 w-full md:w-80 md:ml-4 md:mr-4">
              <div className="flex items-center text-sm text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>
                <span className="font-semibold">RUC:</span>
                <span className="ml-2 text-deep-purple-accent-400 text-nowrap">
                  {stats?.personalDetails?.ruc}
                </span>
              </div>
              <div className="flex items-center text-sm text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                  />
                </svg>
                <span className="font-semibold truncate">Razon Social:</span>
                <span className="ml-2 text-deep-purple-accent-400 text-nowrap">
                  {stats?.personalDetails?.taxName}
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-2 w-full md:w-60 border-t md:border-none pt-4 md:pt-0">
              <div className="flex items-center text-sm text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>
                <span className="font-semibold">Telefono:</span>
                <span className="ml-2 text-deep-purple-accent-400">
                  {stats?.personalDetails?.tel}
                </span>
              </div>
              <div className="flex items-center text-sm text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
                  />
                </svg>
                <span className="font-semibold">Email:</span>
                <span className="ml-2 text-deep-purple-accent-400 truncate w-[70%]">
                  {stats?.personalDetails?.email}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientStats;
