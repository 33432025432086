import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import {
  approveOrDenyAppointment,
  getCalendarEventsOfPatient,
} from "../../firebase/firebase.selfpatient";
import AppointmentArrowCard from "../UI/AppointmentArrowCard";
import {
  dateFormatter,
  dateTimeFormatter,
  timeFormatter,
} from "../../utils/dateFormatter";
import { isFuture, isPast } from "date-fns";
import { auth } from "../../firebase/firebase";

const SelfCalendarHandler = ({ patientsData }) => {
  const [allPatients, setAllPatients] = useState(null);
  const [isInPast, setIsInPast] = useState([]);
  const [isInFuture, setIsInFuture] = useState([]);
  const [isToday, setIsToday] = useState([]);
  const [userView, setUserView] = useState("unconfirmed");
  const [userViewData, setUserViewData] = useState([]);

  const [toBeConfirmed, setToBeConfirmed] = useState([]);
  const localPraxis = useSelector((state) => state.user);
  const user = useSelector((state) => state.user?.userDetails);

  let viewOptions = [
    {
      title: "Calendario",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
          />
        </svg>
      ),
    },
    {
      title: "Recordatorios",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
          />
        </svg>
      ),
    },
  ];

  useEffect(() => {
    const getCalendarEntries = async () => {
      let calendarEntries = await getCalendarEventsOfPatient(
        user?.patientRecordId
      );
      if (JSON.stringify(allPatients) !== JSON.stringify(calendarEntries)) {
        setAllPatients(calendarEntries);

        // Temporary arrays for categorizing entries
        let pastEntries = [];
        let todayEntries = [];
        let futureEntries = [];
        let unconfirmedEntries = [];

        calendarEntries?.map((entry) => {
          const entryDate = new Date(entry?.start?.seconds * 1000); // Convert from timestamp to Date object
          const now = new Date();
          const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
          ); // Midnight of today

          // Helper function to check if two dates fall on the same day
          const isSameDay = (date1, date2) => {
            return (
              date1.getFullYear() === date2.getFullYear() &&
              date1.getMonth() === date2.getMonth() &&
              date1.getDate() === date2.getDate()
            );
          };
          if (entryDate < today) {
            pastEntries.push(entry);
          } else if (entryDate > today) {
            if (entry?.confirmed === null) {
              unconfirmedEntries.push(entry);
            }
            futureEntries.push(entry);
          } else if (isSameDay(entryDate, now)) {
            if (entry?.confirmed === null) {
              unconfirmedEntries.push(entry);
            }
            todayEntries.push(entry);
          }
        });

        const sortedPastEntries = (toSort) => {
          let sortedData = toSort.sort((a, b) => {
            return b.start.seconds - a.start.seconds;
          });
          return sortedData;
        };

        setIsInPast(sortedPastEntries(pastEntries));
        setIsToday(sortedPastEntries(todayEntries));
        setIsInFuture(sortedPastEntries(futureEntries));
        setToBeConfirmed(sortedPastEntries(unconfirmedEntries));

        if (unconfirmedEntries?.length === 0) {
          setUserView("future");
        }
      }
    };
    if (user) {
      getCalendarEntries();
    }
  }, [user]);

  useEffect(() => {
    switch (userView) {
      case "all":
        return setUserViewData(allPatients);
      case "unconfirmed":
        return setUserViewData(toBeConfirmed);
      case "today":
        return setUserViewData(isToday);
      case "past":
        return setUserViewData(isInPast);
      case "future":
        return setUserViewData(isInFuture);
    }
  }, [userView, allPatients]);

  const approveAppointment = async (entry) => {
    debugger;
    let updatedValue = await approveOrDenyAppointment(
      entry?.patientId,
      entry?.event_id,
      true
    );
    if (updatedValue) {
      const updatedValueId = updatedValue.event_id;
      setUserViewData((prevUserViewData) => {
        // Create a new array with the updated value replacing the old one
        return prevUserViewData.map((entry) =>
          entry.event_id === updatedValueId ? updatedValue : entry
        );
      });
    }
  };

  const denyAppointment = async (entry) => {
    let updatedValue = await approveOrDenyAppointment(
      entry?.patientId,
      entry?.event_id,
      false
    );
    if (updatedValue) {
      const updatedValueId = updatedValue.event_id;
      setUserViewData((prevUserViewData) => {
        // Create a new array with the updated value replacing the old one
        return prevUserViewData.map((entry) =>
          entry.event_id === updatedValueId ? updatedValue : entry
        );
      });
    }
  };

  return (
    <>
      <div className="">
        {allPatients ? (
          <>
            <div className="">
              <div>
                <div>
                  <div className="flex justify-between rounded-sm border-gray-200 w-full">
                    {toBeConfirmed?.length < 0 ? (
                      <div className={`m-1 p-1 flex text-black rounded-md`}>
                        No tienes Citas por confirmar
                      </div>
                    ) : (
                      <div class="w-full mx-auto p-6 bg-slate-800 rounded-lg shadow-md">
                        <div className="flex flex-col">
                          <div className="flex justify-start">
                            <p
                              class={`text-sm font-semibold text-white mb-6 mx-1 ${
                                userView === "unconfirmed"
                                  ? "text-lime-400"
                                  : ""
                              }`}
                              onClick={() => setUserView("unconfirmed")}
                            >
                              A Confirmar
                            </p>
                            <p
                              class={`text-sm font-semibold text-white mb-6 mx-1 ${
                                userView === "future" ? "text-lime-400" : ""
                              }`}
                              onClick={() => setUserView("future")}
                            >
                              Futuras
                            </p>
                            <p
                              class={`text-sm font-semibold text-white mb-6 mx-1 ${
                                userView === "past" ? "text-lime-400" : ""
                              }`}
                              onClick={() => setUserView("past")}
                            >
                              Pasadas
                            </p>
                            <p
                              class={`text-sm font-semibold text-white mb-6 mx-1 ${
                                userView === "all" ? "text-lime-400" : ""
                              }`}
                              onClick={() => setUserView("all")}
                            >
                              Todas
                            </p>
                          </div>
                        </div>

                        <ul class="space-y-4 overflow-scroll h-[68vh]">
                          {userViewData && userViewData.length > 0 ? (
                            userViewData?.map((e) => {
                              return (
                                <li class="flex sm:flex-row flex-col sm:items-center items-left justify-between bg-slate-700 p-4 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200">
                                  <div class="flex items-start space-x-4">
                                    <div class="text-left">
                                      <div className="flex flex-col items-left">
                                        <h3 class="text-lg font-medium text-white mr-3">
                                          Praxis: {e?.praxisName}
                                        </h3>
                                        <p class="text-sm text-slate-400">
                                          {e?.confirmed !== null ? (
                                            e?.confirmed ? (
                                              <div className="text-green-300 font-extrabold">
                                                Confirmado
                                              </div>
                                            ) : (
                                              <div className="text-red-300 font-extrabold">
                                                Rechazado
                                              </div>
                                            )
                                          ) : (
                                            <div>Sin Confirmar</div>
                                          )}
                                        </p>
                                      </div>
                                      <div className="">
                                        <div className="text-white">
                                          {" "}
                                          {dateFormatter(
                                            new Date(e?.start?.toDate())
                                          )}
                                        </div>
                                        <p class="text-sm text-slate-400">
                                          {timeFormatter(
                                            new Date(e?.start?.toDate())
                                          )}{" "}
                                          -{" "}
                                          {timeFormatter(
                                            new Date(e?.end?.toDate())
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {userView !== "past" &&
                                    userView !== "all" &&
                                    e?.confirmed === null && (
                                      <div className="flex mt-2 sm:mt-0 sm:justify-center justify-start">
                                        <button
                                          onClick={() => approveAppointment(e)}
                                          className="bg-green-500 sm:mx-1 text-white px-2 py-1 rounded-lg font-medium hover:bg-green-600 transition-colors duration-150"
                                        >
                                          Confirmar
                                        </button>
                                        <button
                                          onClick={() => denyAppointment(e)}
                                          className="bg-red-500 sm:mx-1 mx-3 text-white px-2 py-1 rounded-lg font-medium hover:bg-red-600 transition-colors duration-150"
                                        >
                                          Rechazar
                                        </button>
                                      </div>
                                    )}
                                </li>
                              );
                            })
                          ) : (
                            <div
                              className={`m-1 p-1 flex text-white rounded-md`}
                            >
                              No tienes Citas en esta categoria
                            </div>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            className="flex items-center justify-center"
            style={{ height: "90vh" }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default SelfCalendarHandler;
