import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "../UI/Dropdown";
import { addOrUpdateCalendarEvent } from "../../firebase/firebase.calendar";
import { generateUuid } from "../../firebase/firebase.helpers";
import { useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import PatientsDropdown from "../UI/PatientsDropdown";
import { DatePickerIcon } from "../UI/DatePickerIcon";
import ServicesDropdown from "../UI/ServicesDropdown";
import CalendarModalControllers from "./CalendarModalControllers";

const CalendarAdderModal = ({
  patients,
  scheduler,
  refreshCalendar,
  convertedEvents,
  setNewConvertedEvents,
  calendarRef,
}) => {
  const event = scheduler.edited;

  const [title, setTitle] = useState(event ? event?.title : "");
  const [typeOfEvent, setTypeOfEvent] = useState(
    scheduler?.state?.title !== undefined ? "patient" : "event"
  );
  const [eventInput, setEventInput] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [allPatients, setAllPatients] = useState(event ? event?.patients : []);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [start, setStart] = useState(
    event !== undefined
      ? new Date(event?.start)
      : new Date(calendarRef?.current?.scheduler?.selectedRange.start)
  );
  const [end, setEnd] = useState(
    event
      ? new Date(event?.end)
      : new Date(calendarRef?.current?.scheduler?.selectedRange.end)
  );
  const [eventId, setEventId] = useState(
    event ? event?.event_id : generateUuid()
  );
  const [isConstant, setIsConstant] = useState(
    event ? event.isConstant : false
  );
  const [howOften, setHowOften] = useState(event ? event.howOften : null);
  const [color, setColor] = useState(event ? event.color : null);
  const [praxisId, setPraxisId] = useState(event ? event.praxisId : null);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  const tests = useSelector((state) => state.user);
  let patient = {
    event_id: eventId,
    title: `${selectedPatient?.name}${
      selectedService ? ` - ${selectedService?.reason}` : ""
    }`,
    patientId: selectedPatient?.id,
    patientTel: selectedPatient?.patientTel,
    start: start,
    end: end,
    isConstant: isConstant,
    howOften: howOften,
    isActive: true,
    selectedService: selectedService?.id ? selectedService?.id : 0,
    selectedServiceName: selectedService?.reason
      ? selectedService?.reason
      : null,
    patientName: selectedPatient?.name,
    contacted: false,
    confirmed: null,
    color: color,
    praxisName: localPraxis?.praxis,
  };

  useEffect(() => {
    setAllPatients(patients);
  }, [patients]);

  useEffect(() => {
    if (patients && event?.patientId) {
      let preSelectedPatient = patients.filter(
        (e) => e.id === event.patientId
      )[0];
      preSelectedPatient.appointmentStart = start;
      setSelectedPatient(preSelectedPatient);
    }
  }, []);

  useEffect(() => {
    if (
      scheduler.edited &&
      scheduler.state &&
      JSON.stringify(scheduler.state.start) !== JSON.stringify(start)
    ) {
      setStart(new Date(scheduler.state.start.value));
    } else if (
      calendarRef.current &&
      JSON.stringify(calendarRef?.current?.scheduler?.selectedRange?.start) !==
        JSON.stringify(start)
    ) {
      setStart(new Date(calendarRef?.current?.scheduler?.selectedRange.start));
    }

    if (
      scheduler.edited &&
      scheduler.state &&
      JSON.stringify(scheduler.state.end) !== JSON.stringify(end)
    ) {
      setEnd(new Date(scheduler.state.end.value));
    } else if (
      calendarRef?.current &&
      JSON.stringify(calendarRef?.current?.scheduler?.selectedDate) !==
        JSON.stringify(end)
    ) {
      setEnd(new Date(calendarRef?.current?.scheduler?.selectedRange.end));
    }

    if (
      scheduler?.edited &&
      scheduler?.state &&
      scheduler?.state?.title !== eventInput
    ) {
      setEventInput(scheduler?.state?.title.value);
    }

    if (scheduler?.edited && scheduler?.state) {
      setColor(scheduler?.edited?.color);
    }
  }, [scheduler]);

  useEffect(() => {
    if (localPraxis) setPraxisId(localPraxis.praxisId);
  }, [localPraxis]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      scheduler.loading(true);

      /**Simulate remote data saving */
      const added_updated_event = await new Promise((res) => {
        /**
         * Make sure the event have 4 mandatory fields
         * event_id: string|number
         * title: string
         * start: Date|string
         * end: Date|string
         */
        // setTimeout(() => {
        if (typeOfEvent == "patient") {
          res(patient);
        } else {
          res({
            event_id: eventId,
            title: `${eventInput}`,
            start: start,
            end: end,
            isConstant: isConstant,
            howOften: howOften,
            isActive: true,
            selectedService: null,
            selectedServiceName: null,
            patientName: null,
            contacted: null,
            confirmed: null,
            color: color,
          });
        }
        // }, 500);
      });
      let allEvents = [];

      if (convertedEvents) {
        allEvents = [...convertedEvents];
      }
      allEvents.push(added_updated_event);
      // setNewConvertedEvents(allEvents);
      if (event === undefined) {
        let newEvents = await addOrUpdateCalendarEvent(
          added_updated_event,
          "create",
          praxisId
        );
        scheduler.onConfirm(added_updated_event, "create");
      } else {
        let newEvents = await addOrUpdateCalendarEvent(
          added_updated_event,
          "edit",
          praxisId
        );
        scheduler.onConfirm(added_updated_event, "edit");
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      scheduler.loading(false);
      scheduler.close();
    }
  };

  const handleSubmitData = async () => {
    if (eventInput === "") {
      setNewConvertedEvents(
        convertedEvents?.length > 0 ? [...convertedEvents, patient] : [patient]
      );
    }
    handleSubmit();
  };

  let formStyle = `text-black placeholder-gray-300 w-full px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-100 ring-offset-current ring-offset-2 ring-gray-400`;

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: "10000" }}
      >
        <div className="relative my-6 mx-auto sm:w-80vw md:w-70vw lg:w-60vw xl:w-60vw 2xl:w-60vw w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12">
            {/*header*/}
            {
              <>
                <div className="flex items-center w-full justify-center p-5 m-2 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Agregar Cita</h3>
                </div>
                <div className="px-5 pb-5 mt-3">
                  <div className="mb-5">
                    <CalendarModalControllers
                      setSelectedCategory={setTypeOfEvent}
                      currentCategory={typeOfEvent}
                    />
                  </div>

                  {typeOfEvent === "patient" ? (
                    <div className="flex flex-col">
                      {allPatients && (
                        <div className="mb-10">
                          <label className="text-sm">
                            <span className="text-red-500 pr-1">*</span>
                            Por Favor elija al paciente
                          </label>
                          <PatientsDropdown
                            data={allPatients ? allPatients : []}
                            currentValueSetter={setSelectedPatient}
                            defaultValue={selectedPatient}
                          />
                        </div>
                      )}

                      {selectedPatient && (
                        <div className="mb-10">
                          <label className="text-sm">
                            <span className="text-red-500 pr-1">*</span>
                            Seleccione una razon de su visita
                          </label>
                          {selectedPatient && (
                            <ServicesDropdown
                              userId={selectedPatient?.id}
                              setSelectedService={setSelectedService}
                              defaultValue={eventInput}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="m-2 w-full">
                      <label className="">
                        Nombre del Evento
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="relative p-2 flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md sm:text-sm"
                        required
                        value={eventInput}
                        onChange={(e) => setEventInput(e.target.value)}
                      />
                    </div>
                  )}

                  {
                    <div>
                      <label className="text-sm">
                        <span className="text-red-500 pr-1">*</span>Seleccione
                        un dia y hora
                      </label>
                      <div className="my-4 flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
                        <div className="mx-2 flex flex-col">
                          <label>Desde</label>
                          <DatePicker
                            selected={start}
                            onChange={(date) => {
                              setStart(date);
                              let minDate = new Date(
                                date.getTime() + 15 * 60000
                              );
                              setEnd(minDate);
                            }}
                            timeIntervals={15}
                            showTimeSelect
                            dateFormat={"dd/MM/yyyy HH:mm"}
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5"
                            popperPlacement="top-start"
                            showIcon
                            icon={DatePickerIcon}
                          />
                        </div>
                        <div className="mx-2 flex flex-col">
                          <label>Hasta</label>
                          <DatePicker
                            selected={end}
                            onChange={(date) => setEnd(date)}
                            timeIntervals={15}
                            showTimeSelect
                            dateFormat={"dd/MM/yyyy HH:mm"}
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5"
                            popperPlacement="top"
                            showIcon
                            icon={DatePickerIcon}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  <div className="mx-3 flex">
                    <div>
                      <div className="flex">
                        <label>Se Repite?</label>
                        <input
                          type="checkbox"
                          className="m-2"
                          onClick={() => setIsConstant(!isConstant)}
                        />
                      </div>
                      {isConstant && (
                        <>
                          <Dropdown
                            data={[
                              // { title: "Lunes a Viernes", id: 1 },
                              // { title: "Sabados y Domingos", id: 2 },
                              { title: "Todos los lunes", id: 3 },
                              { title: "Todos los Martes", id: 4 },
                              { title: "Todos los Miercoles", id: 5 },
                              { title: "Todos los Jueves", id: 6 },
                              { title: "Todos los Viernes", id: 7 },
                              { title: "Todos los Sabados", id: 8 },
                              // { title: "Nunca", id: 9 },
                            ]}
                            currentValueSetter={setHowOften}
                            defaultValue={"Todos los lunes"}
                            expectedValue="id"
                          />
                          <span className="text-sm">
                            Obs: La repeticion es por 3 meses
                          </span>
                        </>
                      )}
                    </div>

                    <div className="flex flex-row-reverse"></div>

                    <div className="mx-3 flex justify-between">
                      <div className="flex">
                        <label
                          for="hs-color-input"
                          class="block text-sm font-medium mb-2 flex items-center"
                        >
                          Color
                        </label>
                        <input
                          type="color"
                          className="p-1 h-7 w-7 ml-2 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                          id="hs-color-input"
                          value={color}
                          defaultValue={"#1976d2"}
                          onChange={(e) => setColor(e.target.value)}
                          title="Elige un color"
                        />
                      </div>

                      <div className="flex flex-row-reverse"></div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={scheduler.close}
                  >
                    Cerrar
                  </button>
                  <div className="flex bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                    <button
                      className=""
                      type="button"
                      onClick={handleSubmitData}
                    >
                      Guardar
                    </button>
                    {isLoading && (
                      <div className="ml-2">
                        <Spinner size="small" fill="fill-emerald-800" />
                      </div>
                    )}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 bg-black"></div>
    </>
  );
};

export default CalendarAdderModal;
