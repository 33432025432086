import { useState } from "react";

const useEmail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const sendVerifyAccount = async (patientEmail, patientId) => {
    setLoading(true);
    setError(null);
    try {
      console.log(process.env.REACT_APP_ENV);
      debugger;
      const response = await fetch(
        process.env.REACT_APP_ENV === "DEV"
          ? "https://sendverificationemail-kdozmncc2a-uc.a.run.app"
          : "https://us-central1-odontostark-66ab6.cloudfunctions.net/sendVerificationEmail",
        // "https://us-central1-odontostark-66ab6.cloudfunctions.net/sendVerificationEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: patientEmail, patientId: patientId }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const authenticateAndCreateNewUser = async (
    patientEmail,
    patientId,
    praxisId
  ) => {
    setLoading(true);
    setError(null);
    try {
      console.log(process.env.REACT_APP_ENV);
      debugger;
      const response = await fetch(
        process.env.REACT_APP_ENV === "DEV"
          ? "https://sendcreateaccountemail-kdozmncc2a-uc.a.run.app"
          : "https://us-central1-odontostark-66ab6.cloudfunctions.net/sendCreateAccountEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: patientEmail,
            userId: patientId,
            praxisId: praxisId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    response,
    sendVerifyAccount,
    authenticateAndCreateNewUser,
  };
};

export default useEmail;
